import api from "./api/api";
import apiConfig from "./api/apiConfig"

export default {
  namespaced: true,
  state: {
    eventSlug: '',
    event: {},
    pages: {},
  },
  getters: {
    getEvent: state => {
      return state.event;
    },
    getEventId: state => {
      return state.event.id;
    },
    getEventSlug: state => {
      return state.event.slug;
    },
    getEventTitle: state => {
      return state.event.title
    },
    getNavigation: state => {
      return state.event.navigation
    },
    getSettings: state => (setting) => {
      return state.event.meta.settings[setting] || false
    },
    getTabTitle: state => (tab) => {
      return state.event.meta.tabLabels[tab] || ''
    }
  },
  mutations: {
    SET_EVENT_SLUG(state, value) {
      state.eventSlug = value
    },
    UPDATE_EVENT(state, value) {
      state.event = value
    },
    UPDATE_PAGES(state, value) {
      state.pages = value
    },
    UPDATE_ROOM(state, value) {
      state.rooms = value
    }
  },
  actions: {
    getEvent({commit, dispatch, state,}, eventSlug) {
      // console.log('hostname', apiConfig.hostname)
      
      if (eventSlug) {
        eventSlug = eventSlug.replace(/\/e\//g, '').replace(/\//g, '')
        commit('SET_EVENT_SLUG', eventSlug)
      } 
      return new Promise(async (resolve, reject) => {
        try {
          // let slug = eventSlug || state.eventSlug || (apiConfig.hostname === 'localhost' ? '3kpuQuZV293IeL1iVKiuEh' : apiConfig.hostname)
          let slug = eventSlug || state.eventSlug || apiConfig.hostname
          // const response = await api.get(`/events/list/${apiConfig.hostname}`);
          const response = await api.get(`/events/list/${slug}`);
          if (response.data && response.data.length > 0) {
            commit('SET_EVENT_SLUG', response.data[0].slug)
            commit('UPDATE_EVENT', response.data[0])
            commit('UPDATE_PAGES', response.data[0].meta.pages)
            // call actions to retrieve event details for the event slug
            dispatch("navigation/getNavigation", slug, {root: true});
            dispatch("partners/getPartners", slug, {root: true});
            dispatch("sessions/getSessions", slug, {root: true});
            dispatch("attendees/getAttendees", slug, {root: true});
            dispatch("exhibitors/getExhibitors", slug, {root: true});
            dispatch("contests/getContests", slug, {root: true});
            // Update event settings
            if (response.data[0].meta.settings) {
              // updating event settings
              // console.log('updating event settings', response.data[0].meta.settings.menuEnabled);
              commit('appConfig/UPDATE_NAV_MENU_HIDDEN', !response.data[0].meta.settings.menuEnabled || false, {root: true})
            }
            resolve(response.data)
          } else {
            commit('SET_EVENT_SLUG', '')
            commit('UPDATE_EVENT', {})
            commit('UPDATE_PAGES', {})
            dispatch("navigation/clear", slug, {root: true});
            dispatch("partners/clear", slug, {root: true});
            dispatch("sessions/clear", slug, {root: true});
            dispatch("attendees/clear", slug, {root: true});
            dispatch("exhibitors/clear", slug, {root: true});
            dispatch("contests/clear", slug, {root: true});
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  },
}